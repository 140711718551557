import LogRocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";
import { getEnvironment } from "@/helpers";

// extracted from IResponse, IRequest interfaces that are not exported
type IRequest = {
  url: string;
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
};

type IResponse = {
  headers: { [key: string]: string | undefined };
  body?: string;
  method: string;
};

const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup([
  "address1",
  "address2",
  "address",
  "asset",
  "attachment",
  "birthDate",
  "cardProvider",
  "city",
  "channel",
  "companyName",
  "companyLegalName",
  "cvv",
  "displayName",
  "email",
  "expiration",
  "holderName",
  "iban",
  "lastName",
  "latestLocationMapUrl",
  "loginField",
  "loyaltyProgramId",
  "membershipId",
  "memo",
  "name",
  "pan",
  "panToken",
  "password",
  "pin",
  "pinUpdatedAt",
  "session",
  "showpan",
  "ssn",
  "state",
  "swiftCode",
  "taxpayerNumber",
  "token",
  "trackingNumber",
  "trackingUrl",
  "url",
  "informationRequest",
  "informationRequests",
  "accountNumber",
  "beneficiaryBankAddress",
  "beneficiaryBankInfo",
  "beneficiaryBankName",
  "chequeNumber",
  "counterpartyName",
  "recipientName",
  "routingNumber",
  "senderName",
  "verificationToken",
  "resetBackupCodes",
  "number", // mutation CreateSmsChannel uses number field for phone number
  "deviceSecret",
  // Start: Financial Documents Settings Page
  "bankAccountNumber",
  "firstName",
  // End
  "dbaName",
  "transactionDetailString",
  "zip",
  "balance",
  "properties",
  "ifscCode",
  "accountReference",
  "accountHolderName",
  "accountHolderAddress",
  "bankName",
  "bankAddress",
  "customMerchant",
  "alias",
  "expenseFieldStrValue",
  "description",
  "erpApAccountVxOption",
  "erpClassVxOption",
  "erpDepartmentVxOption",
  "erpLocationVxOption",
  "erpPaymentAccountVxOption",
  "userErpCategoryVxOption",
  "reason",
  "searchText",
]);

// these are GraphQL queries/mutations that contain sensitive information that
// the sanitizer can't filter. these filters are NOT applied to the response.
const filteredRequestOperationNames = [
  "SubmitInformationRequest",
  "SaveOnboardingInformationRequest",
];

export default {
  rootHostname: "brex.com",
  release: getEnvironment("APP_VERSION"),
  network: {
    requestSanitizer: (request: IRequest) => {
      // Do not send authorization headers to LogRocket!
      if (request.headers.Authorization) {
        request.headers.Authorization = "__REDACTED__";
      }
      if (request.headers.authorization) {
        request.headers.authorization = "__REDACTED__";
      }

      // This tell us that we have an API request
      if (request.url.startsWith(getEnvironment("API_HOST") as string)) {
        // Redact these requests in their entirety because the data is sent as a JSON
        // string, not as key-value pairs, so the LogRocket sanitizer doesn't
        // catch them
        const shouldRedact = filteredRequestOperationNames.some(
          (operationName) => request.url.includes(operationName),
        );
        if (shouldRedact) {
          return null;
        }
      }

      // apply the LogRocketFuzzySanitizer and return filtered request
      return requestSanitizer({ ...request, body: request.body ?? "" });
    },
    responseSanitizer: (response: IResponse) => {
      // Apply the LogRocketFuzzySanitizer.
      const cleanResp = responseSanitizer({
        ...response,
        body: response.body ?? "",
      });
      // Add any one-off exclusions here.
      return cleanResp;
    },
  },
};
