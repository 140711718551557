import { datadogRum } from "@datadog/browser-rum";
import LogRocket from "logrocket";
import * as React from "react";
import { useControllerPerformanceContext } from "@/domains/App/components/Controller/ControllerContext";
import { useAnalyticsMetadataContext } from "@/features/Analytics/contexts/AnalyticsMetadata";
import {
  getAnonymousId,
  resetAnonymousId,
} from "@/features/Analytics/helpers/anonymousId";
import {
  getSegmentDestinationsFromCookies,
  isCategoryIntegrationsEnabled,
} from "@/features/Analytics/helpers/cookieManagement";
import { initCoreVitals } from "@/features/Analytics/helpers/coreWebVitals";
import { initDatadog } from "@/features/Analytics/helpers/datadog";
import type { FilteredUserProperties } from "@/features/Analytics/helpers/filterUserProperties";
import { filterUserProperties } from "@/features/Analytics/helpers/filterUserProperties";
import loadSegmentScript from "@/features/Analytics/helpers/loadSegmentScript";
import {
  isLogRocketEnabled,
  logRocketToken,
} from "@/features/Analytics/helpers/logRocket";
import logRocketConfig from "@/features/Analytics/helpers/logRocketConfig";
import { segmentBatchConfig } from "@/features/Analytics/helpers/segment";
import {
  setExtraSentryContext,
  setSentryContext,
  initSentry,
} from "@/features/Analytics/helpers/sentry";
import { trackEvent as analyticsTrackEvent } from "@/features/Analytics/helpers/trackEvent";
import { getWorkflowId } from "@/features/Analytics/helpers/workflowSessionManager";
import type {
  UserProperties,
  TrackFunctionType,
  MarketoCustomFields,
} from "@/features/Analytics/sharedTypes";
import { useLaunchDarklyInitialization } from "@/features/LaunchDarkly/hooks/useLaunchDarklyInitialization";
import { getEnvironment, inBrowserTest } from "@/helpers/environment";
import { internalTrackError } from "@/helpers/errorTracking";
import { urls } from "@/helpers/urls";

let hasLoadedSegment = false;

/**
 * Synchronous initialization of third party analytics
 */
const init = (): string => {
  if (!hasLoadedSegment) {
    // load the Segment library
    loadSegmentScript();
  }

  // initialize anonymous ID
  const anonymousId = getAnonymousId();

  const segmentDestinations = getSegmentDestinationsFromCookies();

  // initialize Datadog, only if user accepted cookies for strictly necessary integrations
  initDatadog();
  initCoreVitals();
  initSentry();

  // initialize LogRocket
  if (isLogRocketEnabled) {
    try {
      LogRocket.init(logRocketToken as string, logRocketConfig);
    } catch (err) {
      console.error("Failed to initialize LogRocket");
      internalTrackError({
        errorName: "Failed to initialize LogRocket",
        error: err as Error,
      });
    }
  }

  // load Segment
  const segmentWriteKey = getEnvironment("SEGMENT_WRITE_KEY");
  if (
    // make sure we have a write key
    segmentWriteKey &&
    // make sure we're not in a browser test
    !inBrowserTest() &&
    // Reset password route should not load analytics to leak token in the path
    !window.location?.pathname.startsWith(urls.users.passwordReset()) &&
    // prevent analytics.load from being called multiple times
    !hasLoadedSegment
  ) {
    try {
      window.analytics.load(segmentWriteKey, {
        integrations: { ...segmentBatchConfig, ...segmentDestinations },
      });
      hasLoadedSegment = true;
    } catch (err) {
      console.error("Failed to load Segment");
      internalTrackError({
        errorName: "Failed to load Segment",
        error: err as Error,
      });
    }
  }

  window.analytics.ready(() => {
    // track initial page view
    window.analytics.page();
    // make sure Segment uses our custom anonymousId instead of generating
    // its own to ensure IDs across services can be cross referenced
    // @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#override-the-default-anonymous-id-with-a-call
    window.analytics.user().anonymousId(anonymousId);
  });

  return anonymousId;
};

/**
 * Track when a user views a page
 * @see https://segment.com/docs/connections/spec/page/
 */
const page = () => {
  try {
    const segmentDestinations = getSegmentDestinationsFromCookies();

    window.analytics.page({ integrations: segmentDestinations });
  } catch (err) {
    console.error("Failed to track page view in Segment");
    internalTrackError({
      errorName: "Failed to track page view in Segment",
      error: err as Error,
    });
  }
};

type LRUserTraits = Record<string, string | number | boolean>;

export type AnalyticsContextValue = {
  identify: (
    userId: string | undefined,
    userProperties?: UserProperties,
    options?: SegmentAnalytics.SegmentOpts,
  ) => Promise<void>;
  identifyMarketo: (
    userId: string,
    userTraits?: MarketoCustomFields,
    options?: SegmentAnalytics.SegmentOpts,
  ) => Promise<void>;
  analyticsUserProperties: UserProperties | null;
  datadogSessionId?: string;
  initialize: () => Promise<void>;
  logRocketSessionUrl?: string;
  reset: () => Promise<void>;
  shutdown: () => Promise<void>;
  trackEvent: typeof analyticsTrackEvent;
  trackPage: typeof page;
};

const AnalyticsContext = React.createContext<AnalyticsContextValue>({
  identify: () => Promise.resolve(),
  identifyMarketo: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
  reset: () => Promise.resolve(),
  shutdown: () => Promise.resolve(),
  trackEvent: () => {},
  trackPage: () => {},
  analyticsUserProperties: null,
});

export const AnalyticsProvider: React.FC = ({ children }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [analyticsUserProperties, setAnalyticsUserProperties] =
    React.useState<UserProperties | null>(null);
  const [logRocketSessionUrl, setLogRocketSessionUrl] = React.useState<
    string | undefined
  >(undefined);
  const [datadogSessionId, setDatadogSessionId] = React.useState<
    string | undefined
  >(undefined);

  const {
    identify: ldIdentify,
    initialize: ldInitialize,
    shutdown: ldShutdown,
  } = useLaunchDarklyInitialization();

  /**
   * Initialize analytics
   */
  const initialize: AnalyticsContextValue["initialize"] =
    React.useCallback(async () => {
      const anonymousId = init();
      // this will resolve immediately since we're bootstrapping LaunchDarkly
      // @see https://docs.launchdarkly.com/sdk/features/bootstrapping#javascript
      await ldInitialize(anonymousId);

      if (isLogRocketEnabled) {
        LogRocket.getSessionURL((sessionUrl) => {
          setLogRocketSessionUrl(sessionUrl);
          datadogRum.setGlobalContextProperty("logRocketUrl", sessionUrl);
        });
      }

      setDatadogSessionId(datadogRum.getInternalContext()?.session_id);
      setInitialized(true);
    }, [ldInitialize]);

  /** Identify LD before other tools, so that the other tools may take accurate flag values into account. */
  const identifyLaunchDarkly = React.useCallback(
    async (
      userId: string,
      filteredUserProperties: FilteredUserProperties,
      userProperties: UserProperties,
    ) => {
      // identify user with LaunchDarkly
      try {
        // target only by companyId with encoded ids not customerAccountId for consistency
        const { customerAccountId, ...remainingFilteredUserProperties } =
          filteredUserProperties;
        return await ldIdentify(userId, {
          ...remainingFilteredUserProperties,
          // add back filtered email until we fully audit use of email
          // in feature flag targeting
          email: userProperties.email as string,
        });
      } catch (err) {
        console.error("Failed to identify user with LaunchDarkly");
        internalTrackError({
          errorName: "Failed to identify user with LaunchDarkly",
          error: err as Error,
        });
      }
    },
    [ldIdentify],
  );
  const identifyAuthenticatedAnalyticsTools = React.useCallback(
    async (
      userId: string,
      filteredUserProperties: FilteredUserProperties,
      options: SegmentAnalytics.SegmentOpts,
    ) => {
      // identify user with Segment
      try {
        window.analytics.identify(userId, filteredUserProperties, options);
      } catch (err) {
        console.error("Failed to identify user in Segment");
        internalTrackError({
          errorName: "Failed to identify user in Segment",
          error: err as Error,
        });
      }

      try {
        // clone `filteredUserProperties` since as of @datadog/browser-rum@4.19.1, `setUser` [mutates](https://github.com/DataDog/browser-sdk/blob/47c235b26b349eea77364165149b90c3abed245e/packages/rum-core/src/boot/rumPublicApi.ts#L248)
        // the input. This is fixed as of v4.24.0 (https://github.com/DataDog/browser-sdk/pull/1801)
        datadogRum.setUser({ ...filteredUserProperties });
      } catch (err) {
        console.error("Failed to identify user with DataDog RUM");
        internalTrackError({
          errorName: "Failed to identify user with DataDog RUM",
          error: err as Error,
        });
      }

      // identify user with LogRocket
      if (isLogRocketEnabled) {
        try {
          // @see https://docs.logrocket.com/reference#identify
          LogRocket.identify(userId, filteredUserProperties as LRUserTraits);
        } catch (err) {
          console.error("Failed to identify user with LogRocket");
          internalTrackError({
            errorName: "Failed to identify user with LogRocket",
            error: err as Error,
          });
        }
      }
    },
    [],
  );

  const identifyAuthenticated = React.useCallback(
    async (
      userId: string,
      filteredUserProperties: FilteredUserProperties,
      userProperties: UserProperties,
      options: SegmentAnalytics.SegmentOpts,
    ) => {
      // 1. identify with LaunchDarkly only. This initializes the correct
      // feature flags for the given userProperties.
      await identifyLaunchDarkly(
        userId,
        filteredUserProperties,
        userProperties,
      );
      // 2. Identify the remainder of analytics tools with the latest properties.
      await identifyAuthenticatedAnalyticsTools(
        userId,
        filteredUserProperties,
        options,
      );
    },
    [identifyAuthenticatedAnalyticsTools, identifyLaunchDarkly],
  );

  const identifyAnonymous = React.useCallback(
    async (
      filteredUserProperties: FilteredUserProperties,
      options: SegmentAnalytics.SegmentOpts,
    ) => {
      const anonymousId = getAnonymousId();

      try {
        /**
         * IMPORTANT:
         * Segment stores information in localStorage called `traits`, and
         * attaches them to every request. when a new user logs in, we want
         * to ensure that the tracking call is not polluted with another user's
         * information. we reset Segment's `traits` in localStorage so we don't
         * attach any unwanted attributes to the anonymous user.
         */
        window.analytics.reset();
        window.analytics.identify(filteredUserProperties, {
          ...options,
          anonymousId,
          integrations: {
            ...options.integrations,
          },
        });
      } catch (err) {
        console.error("Failed to reset user in Segment");
        internalTrackError({
          errorName: "Failed to reset user in Segment",
          error: err as Error,
        });
      }

      // identify anonymous session with LaunchDarkly
      await ldIdentify(anonymousId, {
        ...filteredUserProperties,
        // This is necessary for LaunchDarkly to auto-alias this anonymous ID
        // with the new user ID when the user is eventually identified.
        anonymous: true,
      });

      // identify anonymous session with LogRocket.
      if (isLogRocketEnabled) {
        try {
          /**
           * identify without an ID for anonymous users because adding an
           * anonymousId to this call will create a separate user, and later
           * identify calls won't be associated to the same user.
           * @see https://docs.logrocket.com/reference#identify
           */
          LogRocket.identify(filteredUserProperties as LRUserTraits);
        } catch (err) {
          console.error("Failed to reset user in LogRocket");
          internalTrackError({
            errorName: "Failed to reset user in LogRocket",
            error: err as Error,
          });
        }
      }
    },
    [ldIdentify],
  );

  /**
   * Identify the user of the current session to third party analytics
   */
  const identify: AnalyticsContextValue["identify"] = React.useCallback(
    async (userId, userProperties = {}, options = {}) => {
      // prevent identify if not initialized
      if (!initialized) {
        return;
      }

      setAnalyticsUserProperties(userProperties);
      const filteredUserProperties = filterUserProperties(userProperties);

      // set Sentry context from filtered user properties.
      setSentryContext(filteredUserProperties);

      // authenticated user
      if (userId) {
        identifyAuthenticated(
          userId,
          filteredUserProperties,
          userProperties,
          options,
        );
        return;
      }

      // ANONYMOUS USER
      identifyAnonymous(filteredUserProperties, options);
    },
    [initialized, identifyAuthenticated, identifyAnonymous],
  );

  /**
   * Reset user identity with third party analytics
   */
  const reset: AnalyticsContextValue["reset"] = React.useCallback(async () => {
    // The anonymousId becomes aliased to a specific userId at identification,
    // so we have to generated a new anonymousId (by resetting it) every time a
    // user explicitly logs out.
    resetAnonymousId();

    if (initialized) {
      await identify(undefined);
    }
  }, [identify, initialized]);

  /**
   * Identify the user on Segment specifically for Marketo.
   * Marketo is turned off by default and should only be explicity turned for an event if needed to reduce number of API calls.
   */
  const identifyMarketo: AnalyticsContextValue["identifyMarketo"] =
    React.useCallback(
      async (
        userId: string,
        userTraits?: MarketoCustomFields,
        options?: SegmentAnalytics.SegmentOpts,
      ) => {
        try {
          window.analytics.identify(userId, userTraits, {
            ...options,
            integrations: {
              "Marketo V2": isCategoryIntegrationsEnabled("performance"),
            },
          });
        } catch (err) {
          console.error("Failed to identify user for marketo in Segment");
          internalTrackError({
            errorName: "Failed to identify user for marketo in Segment",
            error: err as Error,
          });
        }
      },
      [],
    );

  /**
   * Clean up any initialized resources
   * To be used when unmounting this context
   */
  const shutdown: AnalyticsContextValue["shutdown"] =
    React.useCallback(async () => {
      // don't clean up anything unless we've initialized first
      if (initialized) {
        // shutdown the LaunchDarkly client
        await ldShutdown();
      }
    }, [initialized, ldShutdown]);

  const trackEvent: AnalyticsContextValue["trackEvent"] = React.useCallback(
    (...args) => {
      if (initialized) {
        analyticsTrackEvent(...args);
      }
    },
    [initialized],
  );

  const trackPage: AnalyticsContextValue["trackPage"] =
    React.useCallback(() => {
      if (initialized) {
        page();
      }
    }, [initialized]);

  React.useEffect(() => {
    if (logRocketSessionUrl) {
      // include the LogRocket session URL for error reports in Sentry
      setExtraSentryContext("logrocket_session_url", logRocketSessionUrl);
      // include the LogRocket URL in a Helpshift custom field
    }
  }, [logRocketSessionUrl]);

  const value = React.useMemo(
    () => ({
      identify,
      identifyMarketo,
      initialize,
      analyticsUserProperties,
      datadogSessionId,
      logRocketSessionUrl,
      reset,
      shutdown,
      trackEvent,
      trackPage,
    }),
    [
      identify,
      identifyMarketo,
      initialize,
      datadogSessionId,
      logRocketSessionUrl,
      reset,
      analyticsUserProperties,
      shutdown,
      trackEvent,
      trackPage,
    ],
  );
  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const MockAnalyticsProvider: React.FC<
  Partial<AnalyticsContextValue>
> = ({
  children,
  identify = () => Promise.resolve(),
  identifyMarketo = () => Promise.resolve(),
  initialize = () => Promise.resolve(),
  datadogSessionId,
  logRocketSessionUrl,
  reset = () => Promise.resolve(),
  shutdown = () => Promise.resolve(),
  trackEvent = () => {},
  trackPage = () => {},
  analyticsUserProperties = null,
}) => (
  <AnalyticsContext.Provider
    value={{
      identify,
      identifyMarketo,
      initialize,
      datadogSessionId,
      logRocketSessionUrl,
      analyticsUserProperties,
      reset,
      shutdown,
      trackEvent,
      trackPage,
    }}
  >
    {children}
  </AnalyticsContext.Provider>
);

export const useAnalytics = () => React.useContext(AnalyticsContext);

export const useResetAnalytics = () => {
  const { reset } = useAnalytics();
  return reset;
};

export const useUserPropertiesForAnalytics = () => {
  const { analyticsUserProperties } = useAnalytics();
  return { analyticsUserProperties };
};

export const useTrackEvent = () => {
  const { ancestorControllerNames, controllerName, controllerSessionId } =
    useControllerPerformanceContext();

  const metadataContext = useAnalyticsMetadataContext();
  const metadata =
    typeof metadataContext === "function" ? metadataContext() : metadataContext;

  const { trackEvent } = useAnalytics();
  const wrappedTrackEvent = React.useCallback<TrackFunctionType>(
    (eventName, eventProperties, callback) => {
      trackEvent(
        eventName,
        {
          ...eventProperties,
          ...metadata,
          ancestorControllerNames,
          controllerName,
          controllerSessionId,
          workflowId: getWorkflowId(),
        },
        callback,
      );
    },
    [
      ancestorControllerNames,
      controllerName,
      controllerSessionId,
      metadata,
      trackEvent,
    ],
  );
  return wrappedTrackEvent;
};

export const useTrackPage = () => {
  const { trackPage } = useAnalytics();
  return trackPage;
};

export const useTrackEventOnMount: TrackFunctionType = (...args) => {
  const trackEvent = useTrackEvent();
  React.useEffect(() => {
    trackEvent(...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- trackEvent should only be called once, on mount
  }, []);
};
