import { datadogRum } from "@datadog/browser-rum";
import { Perfume } from "perfume.js";
import { internalTrackError } from "@/helpers/errorTracking";

export const initCoreVitals = () => {
  try {
    new Perfume({
      analyticsTracker: ({
        metricName,
        data,
        navigatorInformation,
        attribution,
        rating,
        navigationType,
      }) => {
        // Recreating the objects here to raise visibility on the content
        const commonMetadata = {
          navigatorInformation,
          attribution,
          rating,
          navigationType,
        };

        switch (metricName) {
          case "navigationTiming":
            // @ts-expect-error types not properly exported
            // IPerfumeData = number | IPerfumeNavigationTiming | IPerfumeNetworkInformation;
            if (data?.timeToFirstByte) {
              datadogRum.addAction("vitals.navigationTiming", {
                data,
                ...commonMetadata,
              });
            }
            break;
          case "networkInformation":
            // @ts-expect-error types not properly exported
            // IPerfumeData = number | IPerfumeNavigationTiming | IPerfumeNetworkInformation;
            if (data?.effectiveType) {
              datadogRum.addAction("vitals.networkInformation", {
                data,
                ...commonMetadata,
              });
            }
            break;
          case "fp":
            datadogRum.addAction("vitals.firstPaint", {
              duration: data,
              ...commonMetadata,
            });
            break;
          case "fcp":
            datadogRum.addAction("vitals.firstContentfulPaint", {
              duration: data,
              ...commonMetadata,
            });
            break;
          case "fid":
            datadogRum.addAction("vitals.firstInputDelay", {
              duration: data,
              ...commonMetadata,
            });
            break;
          case "lcp":
            datadogRum.addAction("vitals.largestContentfulPaint", {
              duration: data,
              ...commonMetadata,
            });
            break;
          case "cls":
            datadogRum.addAction("vitals.cumulativeLayoutShift", {
              duration: data,
              ...commonMetadata,
            });
            break;
          case "clsFinal":
            datadogRum.addAction("vitals.cumulativeLayoutShiftFinal", {
              duration: data,
              ...commonMetadata,
            });
            break;
          case "tbt":
            datadogRum.addAction("vitals.totalBlockingTime", {
              duration: data,
              ...commonMetadata,
            });
            break;
          default:
            datadogRum.addAction(metricName, {
              duration: data,
              ...commonMetadata,
            });
            break;
        }
      },
    });
  } catch (err) {
    internalTrackError({
      errorName: "Failed to initialize Vitals Performacne measurement",
      error: err as Error,
    });
  }
};
