import { getAnonymousId } from "@/features/Analytics/helpers/anonymousId";
import type {
  NonNullUserProperties,
  UserProperties,
} from "@/features/Analytics/sharedTypes";
import { toRawEntityId } from "@/helpers";

export type FilteredUserProperties = NonNullUserProperties & {
  customerAccountId?: string;
  customerUserId?: string;
  delegatorUserId?: string;
  anonymousId?: string;
};

export const filterUserProperties = (
  userProperties: UserProperties,
): FilteredUserProperties => {
  const nonNullUserProperties = Object.keys(userProperties)
    .filter((key) => userProperties[key as keyof UserProperties] !== null)
    .reduce(
      (result, key) => ({
        ...result,
        [key]: userProperties[key as keyof UserProperties],
      }),
      {} as NonNullUserProperties,
    );
  return {
    ...nonNullUserProperties,
    // decode GraphQL node IDs into raw database IDs and track them as
    // custom properties so we can easily correlate these entities with
    // other systems
    customerAccountId: toRawEntityId(userProperties.companyId),
    customerUserId: toRawEntityId(userProperties.id),
    delegatorUserId: toRawEntityId(userProperties.delegatorId),
    // add the anon id so that we can associate rollouts from before they were logged in
    anonymousId: getAnonymousId(),
    // remove Helpshift auth token from user properties
    // so that 3rd-party tools can't see it.
    helpshiftAuthToken: undefined,
    // remove PII
    companyName: undefined,
    companyLegalName: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    name: undefined,
  };
};
