import { datadogRum } from "@datadog/browser-rum";
import React from "react";
import type { Union } from "ts-toolbelt";
import noOp from "@/helpers/noOp";

type ComponentPerformanceEvents =
  | "app.page_component"
  | "app.main_router"
  | "app.main_app"
  | "auth.login_finish_page";
type LoadedPageEvents = `${ComponentPerformanceEvents}.loaded`;
type MountedPageEvents = `${ComponentPerformanceEvents}.mounted`;

type PerformanceEvents =
  | "auth.iframe.init"
  | "auth.iframe.response"
  | "auth.token.fetch"
  | "auth.token.response"
  | "auth.token.error"
  | "auth.config.fetch"
  | "auth.config.response"
  | "auth.login_finish_redirect.finished"
  | "auth.authentication_controller.redirecting_for_authentication"
  | "integrations.export_start"
  | "integrations.export_success"
  | "integrations.export_failure"
  | LoadedPageEvents
  | MountedPageEvents;

const trackPerformanceFactory = () => {
  // ensure browser compatibility with performance API
  if (!window.performance.measure("TEST") || process.env.NODE_ENV === "test") {
    return noOp;
  }

  return <T extends PerformanceEvents>(
    eventName: T,
    eventToTrackFrom?: Union.Exclude<PerformanceEvents, T>,
  ) => {
    window.performance.mark(eventName);

    if (eventToTrackFrom) {
      const performanceMarks = window.performance.getEntriesByName(
        eventToTrackFrom,
        "mark",
      );

      if (!performanceMarks.length) {
        console.warn(
          `No performance mark exists with the name: "${eventToTrackFrom}".
Be sure to execute trackPerformance("${eventToTrackFrom}") before measuring performance with this event.`,
        );
        return;
      }

      try {
        const measure = window.performance.measure(eventName, eventToTrackFrom);
        datadogRum.addTiming(
          `_from_.${eventToTrackFrom}._to_.${eventName}`,
          measure.duration,
        );
      } catch {
        console.error(
          `Unable to measure performance of "${eventToTrackFrom}" -> "${eventName}"`,
        );
      }
    } else {
      // not measuring performance, add event timing
      datadogRum.addTiming(eventName);
    }
  };
};

export const trackPerformance = trackPerformanceFactory();

export const useTrackLoadMountPerformance = (
  tag: ComponentPerformanceEvents,
) => {
  const loadedMeasure = React.useRef(false);
  const mountedMeasured = React.useRef(false);

  if (!loadedMeasure.current) {
    loadedMeasure.current = true;
    trackPerformance(`${tag}.loaded`);
  }

  React.useEffect(() => {
    if (mountedMeasured.current) {
      return;
    }
    mountedMeasured.current = true;
    trackPerformance(`${tag}.mounted`);
  }, [tag]);
};
