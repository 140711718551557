import * as React from "react";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import type {
  AllAnalyticsEventsKeys,
  TrackEventProps,
} from "@/features/Analytics/sharedTypes";

type TrackEventOnMountProps = {
  when?: () => boolean;
};

export const TrackEventOnMount = <TEventName extends AllAnalyticsEventsKeys>({
  children,
  eventName,
  eventProps = {},
  // Used to conditionally send a tracking event
  when = () => true,
}: React.PropsWithChildren<
  TrackEventProps<TEventName> & TrackEventOnMountProps
>) => {
  const trackEvent = useTrackEvent();
  React.useEffect(() => {
    if (when()) {
      trackEvent(eventName, eventProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName]);
  return <>{children}</>;
};
