import { datadogLogs } from "@datadog/browser-logs";
import type { RumFetchResourceEventDomainContext } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";
import type { JsonObject } from "type-fest";
import {
  getEnvironment,
  inSyntheticTest,
  isProduction,
} from "@/helpers/environment";

// these errors should not be tracked because they are expected, created by
// external libraries, and/or have no negative effect.
const DO_NOT_TRACK_ERRORS: (string | RegExp)[] = [
  // ResizeObserver errors have no negative effect and create a ton of noise
  // @see https://stackoverflow.com/a/50387233
  /^Error: ResizeObserver/,
  // third party script errors have no debugging information attached to them
  // for security purposes and are out of our control
  "Script error.",
];

interface NetworkInformation {
  downlink?: number;
  effectiveType?: string;
  rtt?: number;
  saveData?: boolean;
  type?: string;
}

const initDatadogRum = () => {
  const applicationId = getEnvironment("DATADOG_APPLICATION_ID");
  const clientToken = getEnvironment("DATADOG_CLIENT_TOKEN");
  const service = getEnvironment("DATADOG_SERVICE");
  const version = getEnvironment("APP_VERSION");
  if (applicationId && clientToken) {
    try {
      datadogRum.init({
        applicationId,
        clientToken,
        version,
        service,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        traceSampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: false,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        defaultPrivacyLevel: "allow",
        silentMultipleInit: true,
        allowedTracingUrls: [
          {
            match: getEnvironment("API_HOST") as string,
            propagatorTypes: ["tracecontext"],
          },
        ],
        beforeSend: (event, context) => {
          // Ignore certain errors
          if (
            event.type === "error" &&
            DO_NOT_TRACK_ERRORS.some((ignoreError) =>
              ignoreError instanceof RegExp
                ? ignoreError.test(event.error.message)
                : ignoreError === event.error.message,
            )
          ) {
            return false;
          }

          // Ignore click events to avoid tracking user interactions and logging PII
          if (event.type === "action" && event.action.type === "click") {
            return false;
          }

          try {
            // datadog requires for event.context has to be as flat as possible.
            // this means that anything that goes into context cannot be nested more than 1 level deep
            // this is OK ✅: event.context.someParam = "someValue" is ok
            // this is Not OK ❌: event.context.nested.someParam = "someValue"
            let contextEnrichment: JsonObject = {};

            if (event.type === "resource") {
              if (event.resource.type === "fetch") {
                // If the graphql Response has response headers. we attach them to the context event
                // This will add some useful headers like request-id & trace-id out of the box.
                const responseHeaders = (
                  context as RumFetchResourceEventDomainContext
                ).response?.headers;
                if (responseHeaders) {
                  responseHeaders.forEach((value, key) => {
                    contextEnrichment[`responseHeaders.${key}`] = value;
                  });
                }
              }
            }

            // @ts-expect-error navigator.connection should exist on some browsers. See Network Information API: https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
            if (navigator.connection) {
              const networkInformation =
                // @ts-expect-error navigator.connection should exist on some browsers. See Network Information API: https://developer.mozilla.org/en-US/docs/Web/API/Network_Information_API
                navigator.connection as NetworkInformation;
              contextEnrichment = {
                ...contextEnrichment,
                networkDownlink: networkInformation.downlink,
                networkEffectiveType: networkInformation.effectiveType,
                networkRtt: networkInformation.rtt,
                networkSaveData: networkInformation.saveData,
                networkType: networkInformation.type,
              };
            }

            if (Object.keys(contextEnrichment).length !== 0) {
              // datadog requires for event.context has to be as flat as possible.
              // this means that anything that goes into context cannot be nested more than 1 level deep
              // this is OK ✅: event.context.someParam = "someValue" is ok
              // this is Not OK ❌: event.context.nested.someParam = "someValue"
              event.context = {
                ...event.context,
                ...contextEnrichment,
              };
            }
          } catch (e) {
            console.error(e);
          }
          return true;
        },
        // as per https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=browser#setup
        enableExperimentalFeatures: ["feature_flags"],
      });
      if (isProduction || inSyntheticTest()) {
        datadogRum.startSessionReplayRecording();
      }
    } catch {
      console.error("Failed to initialize DataDog RUM");
    }
  }
};

const initDatadogLogs = () => {
  const clientToken = getEnvironment("DATADOG_CLIENT_TOKEN");
  const service = getEnvironment("DATADOG_SERVICE");
  const version = getEnvironment("APP_VERSION");
  if (clientToken) {
    try {
      datadogLogs.init({
        clientToken,
        version,
        sessionSampleRate: 100,
        service,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        silentMultipleInit: true,
      });
    } catch {
      console.error("Failed to initialize DataDog Logs");
    }
  }
};

export const initDatadog = () => {
  initDatadogRum();
  initDatadogLogs();
};
