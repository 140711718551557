import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { getCookieRootDomain } from "@/features/Analytics/helpers/cookies";

const anonymousIdStorageKey = "brexAnonymousId";

/**
 * Generate a UUID for identifying anonymous users, store it in a cookie, and
 * refresh the expiry date (if it already exists).
 *
 * This anonymous ID is used across LaunchDarkly, LogRocket, Sentry, and
 * Segment. It's set to persist for 10 years (indefinitely).
 */
export const getAnonymousId = (): string => {
  const existingAnonymousId = Cookies.get(anonymousIdStorageKey);
  const anonymousId = existingAnonymousId || uuid();
  // refresh the cookie regardless if it already exists
  Cookies.set(anonymousIdStorageKey, anonymousId, {
    expires: 365 * 10,
    domain: getCookieRootDomain(window.location.host),
  });
  return anonymousId;
};

/**
 * Reset the current anonymous ID and return a new one
 */
export const resetAnonymousId = (): string => {
  Cookies.remove(anonymousIdStorageKey);
  return getAnonymousId();
};
